<template>
    <!----Footer Start---->
        <div class="ms_footer_wrapper">
            <div class="ms_footer_logo ">
                <router-link to="/"><img class="footer_logo" src="../../assets/images/Sudani_logo.png" alt=""></router-link>
            </div>
            <!----Copyright---->
            <div class="col-lg-12">
                <div class="ms_copyright">
                    <div class="footer_border"></div>
                    <p>Copyright &copy; 2022 <a href="#">BYTE Co.ltd</a>. All Rights Reserved.</p>
                </div>
            </div>
        </div>
</template>

<script>
export default {
  name: 'AppFooter'
}
</script>

<style>

</style>